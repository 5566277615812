<template>
    <a-card style="background-color: #fff;">
        <div>
            <a-form layout="horizontal">
                <div :class="advanced ? null : 'fold'">
                    <a-row>
                        <a-col :md="6" :sm="24" style="max-width: 300px;">
                            <a-form-item label="文件名称" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                <a-input v-model="queryData.fileName" placeholder="请输入文件名称" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="6" :sm="24" style="max-width: 500px;">
                            <a-form-item label="销售方公司" :labelCol="{ span: 6 }" :wrapperCol="{ span: 15, offset: 1 }">
                                <a-select v-model="queryData.sellerId" style="width: 100%" placeholder="请选择销售方信息">
                                    <a-select-option :value="item.id" v-for="item in sellerList" :key="item.id">
                                        {{ item.name }}
                                    </a-select-option>
                                </a-select>
                            </a-form-item>
                        </a-col>
                        <a-col :md="6" :sm="24" style="max-width: 300px;">
                            <a-form-item label="更新时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                <a-range-picker :value="dateValue" :ranges="ranges" @change="dateChange" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="6" :sm="24">
                            <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                            <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                @click="search">查询</a-button>
                        </a-col>
                    </a-row>
                    <a-row>
                        <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                            :disabled="this.selectedRows.length == 0" @click="handleDown">批量下载</a-button>
                    </a-row>
                    <div style="margin: 10px 0;font-size: 15px;margin-left: 10px;">显示第 {{ total > 0 ? 1 : 0 }} 至 {{
                    queryData.size > total ? total : queryData.size }} 项结果，共 {{ total }} 项</div>
                </div>
            </a-form>
        </div>
        <div>
            <a-table bordered :row-selection="rowSelection" :expanded-row-keys.sync="expandedRowKeys" :columns="columns"
                :dataSource="dataSource" :loading="tableLoading" :pagination="false">
                <div slot="modifyTime" slot-scope="text">
                    <span v-if="text">{{ formatDateMin(text) }}</span>
                </div>

                <div slot="action" slot-scope="text,record">
                    <a @click="ylChange(record)">预览</a>
                    <a @click="handleDown(record, 1)" style="margin-left: 5px;">下载</a>
                </div>
            </a-table>
            <div class="page-box">
                <a-pagination :total="total" :current="queryData.page + 1" :page-size-options="pageSizeOptions"
                    :pageSize="queryData.size" @change="handlePageChange" @showSizeChange="onShowSizeChange"
                    show-size-changer />
            </div>
        </div>

        <a-modal :visible="openDown" :title="downTitle" @ok="handleDownAll" @cancel="handleCancel">
            <div>
                <p>*如无客户需先添加客户</p>
                <a-select v-model="downForm.customerId" style="width: 100%" placeholder="请选择客户" :default-value="null"
                    option-label-prop="label">
                    <a-select-option :value="item.id" :label="item.name" v-for=" (item, index) in customerData"
                        :key="index">
                        {{ item.name }}
                    </a-select-option>
                </a-select>
            </div>
            <template slot="footer">
                <a-button key="back" @click="handleCancel">
                    取消
                </a-button>
                <a-button key="submit" type="primary" :loading="btnLoading" @click="handleDownAll">
                    确 认
                </a-button>
            </template>
        </a-modal>
    </a-card>
</template>

<script>
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
const columns = [
    { title: '序号', customRender: (text, record, index) => index + 1, align: 'center' },
    { title: '销售方公司', dataIndex: 'sellerName', align: 'center' },
    { title: '文件类型', dataIndex: 'lableName', align: 'center', scopedSlots: { customRender: 'lableName' } },
    { title: '文件名称', dataIndex: 'fileName', align: 'center', },
    { title: '文件格式', dataIndex: 'fileFormat', align: 'center' },
    { title: '最后更新时间', dataIndex: 'modifyTime', align: 'center', scopedSlots: { customRender: 'modifyTime' } },
    { title: '操作人', dataIndex: 'staffId', align: 'center', width: 100 },
    { title: '操作', align: 'center', scopedSlots: { customRender: 'action' }, width: 150 },
]
export default {
    name: 'QueryList',
    data() {
        return {
            moment,
            customerData: [],
            userData: [],
            advanced: true,
            columns: columns,
            dataSource: [],
            selectedRows: [],
            current: 1,
            dateValue: '',
            total: 0,
            // 筛选
            queryData: {
                page: 0,
                size: 10,
                type: 2
            },
            pageSizeOptions: ['10', '30', '40', '50', '100', '500', '1000'],
            tableLoading: false,
            downLoading: false,
            tabCurrent: 1,
            currentPage: 1,
            searchProv: [],
            searchCity: [],
            ranges: {
                '今天': [moment(), moment()],
                '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '当月': [moment().startOf('month'), moment().endOf('month')],
                '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },
            isModalVisible: false,
            confirmLoading: false,
            formData: {
                name: ''
                // 根据具体需要添加更多字段
            },
            sellerList: [],
            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) => {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                    this.selectedRows = selectedRows
                },
                onSelect: (record, selected, selectedRows) => {
                    console.log(record, selected, selectedRows);
                },
                onSelectAll: (selected, selectedRows, changeRows) => {
                    console.log(selected, selectedRows, changeRows);
                },
            },
            expandedRowKeys: [],
            openDown: false,
            downForm: {
                resourceFileIdList: [],
            },
            selDown: {},
            downTitle: "",
            btnLoading:false
        }
    },
    mounted() {
        // const url = "https://export.youjiamall.cn/2024-08-06/2024-08-06/图书卡PRD水印1722947252866.pdf?e=3445894516&token=VhxwUrvwTpPuK5vKm_wOZkHfTM0neRogNpK37V63:ljuj6wTH8xsVF3n7k4tkY4hyT8I=";

        // fetch(url)
        //     .then(response => {
        //         if (!response.ok) {
        //             throw new Error('Network response was not ok');
        //         }
        //         return response.blob();
        //     })
        //     .then(blob => {
        //         // 创建一个临时的 URL 对象
        //         const url = window.URL.createObjectURL(blob);

        //         // 创建一个虚拟的链接元素，用于触发下载
        //         const link = document.createElement('a');
        //         link.href = url;
        //         link.download = "downloaded_file.pdf"; // 设置下载的文件名
        //         document.body.appendChild(link);
        //         link.click();
        //         document.body.removeChild(link);

        //         // 释放 URL 对象
        //         window.URL.revokeObjectURL(url);
        //     })
        //     .catch(error => {
        //         console.error('文件下载失败', error);
        //     });
        this.getData()
        this.getSeller();
        this.getUserData();
    },
    methods: {
        getUserData() {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/customer/list', 'get').then(res => {
                if (res.data.code == 200) {
                    this.customerData = res.data.data
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        downFileNew(url) {
            let responses;
            fetch(url).then(response => {
                responses = response
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.blob();
            }).then(blob => {
                // 获取文件类型
                const contentType = responses.headers.get('content-type');
                let extension = '.pdf'; // 默认为 PDF 文件

                if (contentType.includes('image/png')) {
                    extension = '.png';
                } else if (contentType.includes('image/jpeg')) {
                    extension = '.jpg';
                }
                // 创建一个临时的 URL 对象
                const url = window.URL.createObjectURL(blob);

                // 创建一个虚拟的链接元素，用于触发下载
                const link = document.createElement('a');
                link.href = url;
                link.download = `downloaded_file${extension}`; // 设置下载的文件名
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                // 释放 URL 对象
                window.URL.revokeObjectURL(url);
            })
                .catch(error => {
                    console.error('文件下载失败', error);
                });
        },
        handleDown(text, type) {
            this.openDown = true;
            this.downForm = {
                resourceFileIdList: []
            }
            this.downTitle = "批量下载投标客户"
            if (type) {
                this.downTitle = "下载投标客户"
                this.selDown = text;
            }
        },
        handleDownAll() {
            if (!this.downForm.customerId) {
                this.$message.error('请选择客户！')
                return false;
            }
            if (this.downTitle == "下载投标客户") {
                this.downForm.resourceFileIdList = []
                this.downForm.resourceFileIdList[0] = this.selDown.id
            } else {
                this.selectedRows.forEach(item => {
                    this.downForm.resourceFileIdList.push(item.id)
                })
            }
            this.downLoad();
        },
        ylChange(record) {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/resourcefilehandle/preview', 'post', {
                id: record.id,
                // customerId:record.
            }).then(res => {
                console.log(res, 'res')
                if (res.data.code == 200) {
                    // let data = res.data;
                    this.downloadFile(res.data.data)
                    this.openDown = false;
                    this.downForm = {
                        resourceFileIdList: []
                    }
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        downLoad() {
            this.btnLoading = true;
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/resourcefilehandle/save', 'post', this.downForm).then(res => {
                this.btnLoading = false;
                if (res.data.code == 200) {
                    let data = res.data;
                    data.data.forEach(item => {
                        this.downFileNew(item)
                    })
                    this.openDown = false;
                } else {
                    this.$message.error(res.data.message);
                }
            });
        },
        downloadFile(url) {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank'; // 打开新标签页，避免替换当前页面
            link.download = url.split('/').pop(); // 使用链接的最后一部分作为文件名
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        // 选择日期
        dateChange(date, dateString) {
            this.dateValue = date
            this.queryData.dateMin = dateString[0].toString();
            this.queryData.dateMax = dateString[1].toString();
        },
        getData() {
            // type 1 合同 2投标
            this.tableLoading = true;
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/resourcefilehandle/list', 'post', this.queryData).then(res => {
                this.tableLoading = false;
                let data = res.data.data;
                if (res.data.code == 200) {
                    this.dataSource = data.data;
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        getSeller() {
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/chart/card/seller/list', 'get').then(res => {
                this.sellerList = res.data.data;
            })
        },
        handleCancel() {
            this.openDown = false
        },

        onShowSizeChange(current, pageSize) {
            this.queryData.size = pageSize;
            this.getData()
        },
        handlePageChange(page) {
            this.queryData.page = page - 1;
            this.currentPage = page;
            this.getData()
        },
        hasPermission(permission) {
            return this.userPermissions.includes(permission);
        },
        reset() {
            this.queryData = {
                page: 0,
                size: 10,
                type: 2
            }
            this.dateValue = ''
            this.searchCity = [];
            this.searchProv = [];
            this.getData()
        },
        search() {
            this.queryData.page = 0;
            this.getData();
        },

        tabPageChange(page) {
            this.queryData.page = page - 1;
            this.tabCurrent = page;
            this.getData()
        },
        exportTask() {
            this.$message.loading('处理中')
            let params = this.queryData;
            params.remarks = ''
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/goods/cake/exportBrand', 'post', params).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('导出成功！')
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        // 格式化年月日
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            // const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
            return result
        },
        formatDateMin(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            return result;
        },
    }
}
</script>

<style lang="less" scoped>
.search {
    // margin-bottom: 54px;
}

.fold {
    width: calc(100% - 216px);
    display: inline-block
}

.operator {
    margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
    .fold {
        width: 100%;
    }
}

::v-deep .page-content {
    padding: 0 !important;
}

.cre-btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    background: #EEECFE;
    border-radius: 4px;
    border: 1px dashed #5542F6;
    cursor: pointer;
    font-size: 16px;
    font-family: PingFangHK-Regular, PingFangHK;
    font-weight: 400;
    color: #5542F6;
}

.btn-box button {
    width: 106px;
    height: 36px;
    margin-left: 10px;
}


.page-box {
    display: flex;
    justify-content: right;
    padding-right: 46px;
    margin-top: 10px;
}
</style>